<template>
  <div>
    <div class="grid crud-demo">
      <h6 class="">Receitas</h6>
      <Toast/>

      <div class="col-12">
        <div class="card widget-boxes  p-0 grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Ano</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Mês</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="60" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Semana</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="80" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Hoje</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>
          </div>
        </div>
      </div>


      <div class="col-12">
        <div class="card">


          <DataTable ref="dt" :value="financeiros" v-model:selection="selectedFinanceiros" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} financeiros" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="Código" :sortable="true" filterField="id" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>
            <Column field="titulo" header="Título" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Título</span>
                {{slotProps.data.titulo}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo título"/>
              </template>
            </Column>

            <Column field="categoria" header="Setor" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Categoria</span>
                {{slotProps.data.categoria}}
              </template>

              <template #filter="{filterModel}">
                <div class="mb-3 text-bold">Agent Picker</div>
                <MultiSelect v-model="filterModel.value" :options="categorias" optionLabel="name" placeholder="Any" class="p-column-filter">
                  <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                      <!--                    <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />-->
                      <span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.option.name}}</span>
                    </div>
                  </template>
                </MultiSelect>
              </template>

            </Column>

            <Column field="tipo" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'financeiro-badge status-' + (slotProps.data.tipo ? slotProps.data.tipo.toLowerCase() : '')">{{slotProps.data.tipo}}</span>
              </template>
            </Column>

            <Column header="Data" filterField="date" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
              </template>
            </Column>

            <Column field="balance" header="Valor" :sortable="true" class="text-right">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.balance)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteFinanceiro(slotProps.data)" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editFinanceiro(slotProps.data)" />
                <!--                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />-->
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="financeiroDialog" :style="{width: '450px'}" header="Detalhes do Financeiro" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/financeiro/' + financeiro.image" :alt="financeiro.image" v-if="financeiro.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Título</label>
              <InputText id="name" v-model.trim="financeiro.titulo" required="true" autofocus :class="{'p-invalid': submitted && !financeiro.titulo}" />
              <small class="p-invalid" v-if="submitted && !financeiro.titulo">Financeiro é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="financeiro.descricao" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="financeiro.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Selecione o tipo">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.tipo">
                    <span :class="'financeiro-badge status-' +slotProps.value.tipo">{{slotProps.value.tipo}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.tipo">
                    <span :class="'financeiro-badge status-' +slotProps.tipo.toLowerCase()">{{slotProps.tipo}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Setor</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="financeiro.category" />
                  <label for="category1">Engenharia</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="financeiro.category" />
                  <label for="category2">Projetos</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="financeiro.category" />
                  <label for="category3">Marketing</label>
                </div>

              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="financeiro.balance" mode="currency" currency="BRL" locale="pt-BR" />
              </div>

            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveFinanceiro" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteFinanceiroDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="financeiro">Confirma a exclusão de <b>{{financeiro.titulo}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteFinanceiroDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteFinanceiro" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteFinanceirosDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="financeiro">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteFinanceirosDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedFinanceiros" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-12">
        <div class="card">
          <h6>Mês Atual</h6>
          <Chart type="bar" :data="comboData" :options="comboOptions" />
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import FinanceiroService from '@/service/FinanceiroService';


export default {
  components: {


  },
  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      financeiros: null,
      financeiroDialog: false,
      deleteFinanceiroDialog: false,
      deleteFinanceirosDialog: false,
      financeiro: {},
      selectedFinanceiros: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'RECEITA', value: 'receita'},
        {label: 'DESPESA', value: 'despesa'},
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },


      pieData: {
        labels: ['Projetos', 'Engenharia', 'Marketing'],
        datasets: [
          {
            data: [540, 325, 702, 421],
            backgroundColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)'
            ]
          }]
      },
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },

      comboData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro'],
        datasets: [{
          type: 'line',
          label: 'Categoria 01',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          data: [0,0,0,0,0,0,0]
        }, {
          type: 'bar',
          label: 'Categoria 02',
          backgroundColor: '#66BB6A',
          data: [0,0,0,0,0,0,0],
          borderColor: 'white',
          borderWidth: 2
        }, {
          type: 'bar',
          label: 'Categoria 03',
          backgroundColor: '#FFA726',
          data: [0,0,0,0,0,0,0]
        }, {
          type: 'bar',
          label: 'Categoria 04',
          backgroundColor: '#268bff',
          data: [0,0,0,0,0,0,0]
        }]
      },
      comboOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }

    }
  },
  financeiroService: null,
  created() {
    this.financeiroService = new FinanceiroService();
    this.initFilters();
  },
  mounted() {


    this.loading1 = false
    // this.financeiroService.getFinanceiros().then((data) => {
    //   console.log('financeirosList mounted')
    //   this.loading1 = false
    //   this.financeiros = data
    //   this.financeiros.forEach(
    //       financeiro => financeiro.date = new Date(financeiro.date)
    //   );
    //
    //   this.financeiros = this.financeiros.filter(function(list_item) {
    //     return list_item.tipo !== "Despesa";
    //   })
    //
    // })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.financeiro = {};
      this.submitted = false;
      this.financeiroDialog = true;
    },
    hideDialog() {
      this.financeiroDialog = false;
      this.submitted = false;
    },
    saveFinanceiro() {
      this.submitted = true;
      if (this.financeiro.titulo.trim()) {
        if (this.financeiro.id) {
          this.financeiro.tipo = this.financeiro.tipo.value ? this.financeiro.tipo.value: this.financeiro.tipo;
          this.financeiros[this.findIndexById(this.financeiro.id)] = this.financeiro;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Financeiro Atualizado', life: 3000});
        }
        else {
          this.financeiro.id = this.createId();
          this.financeiro.code = this.createId();
          this.financeiro.image = 'financeiro-placeholder.svg';
          this.financeiro.tipo = this.financeiro.tipo ? this.financeiro.tipo.value : 'ENGENHARIA';
          this.financeiros.push(this.financeiro);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Financeiro Criado', life: 3000});
        }
        this.financeiroDialog = false;
        this.financeiro = {};
      }
    },
    editFinanceiro(financeiro) {
      this.financeiro = {...financeiro};
      this.financeiroDialog = true;
      // this.$router.push({ path: `/financeiro/${financeiro.id}` });
    },
    confirmDeleteFinanceiro(financeiro) {
      this.financeiro = financeiro;
      this.deleteFinanceiroDialog = true;
    },
    deleteFinanceiro() {
      this.financeiros = this.financeiros.filter(val => val.id !== this.financeiro.id);
      this.deleteFinanceiroDialog = false;
      this.financeiro = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Financeiro Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.financeiros.length; i++) {
        if (this.financeiros[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteFinanceirosDialog = true;
    },
    deleteSelectedFinanceiros() {
      this.financeiros = this.financeiros.filter(val => !this.selectedFinanceiros.includes(val));
      this.deleteFinanceirosDialog = false;
      this.selectedFinanceiros = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Financeiros Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
